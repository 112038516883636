import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import { useContext } from "react";
import { AppContext } from "../context";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

const Hero = () => {
  const { heroData, homeData, setIsHero } = useContext(AppContext);

  const [currentHero, setCurrentHero] = useState("");
  const [prevHeroId, setPrevHeroId] = useState();
  const [newHeroId, setNewHeroId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState();

  const currentHeroRef = useRef();

  // Responsible for monitoring the current Hero item
  useEffect(() => {
    if (heroData.length > 0) {
      setCurrentHero(heroData[0]?.name);
      currentHeroRef.current = heroData[0]?.name;
      setPrevHeroId(
        heroData.find((item) => item.name === currentHeroRef.current)?.id
      );
    }
  }, [heroData]);

  function setHero(item) {
    if (currentHero === item) return "text-black" && "text-transition";
    return "text-gray-300";
  }

  const handleClick = (selectedHero) => {
    const selectedHeroId = heroData.find(
      (item) => item.name === selectedHero
    )?.id;
    if (prevHeroId !== null) {
      setNewHeroId(selectedHeroId);
      setCurrentHero(selectedHero);
      if (newHeroId !== null) {
        setPrevHeroId(newHeroId);
      }
    }
  };

  // Reads Index change which triggers fade in on each change
  useEffect(() => {
    if (currentHero) {
      const itemIndex = heroData.findIndex((item) => item.name === currentHero);
      activeIndex.slideTo(itemIndex);
    }
    setNextIndex(activeIndex.activeIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHero]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setIsHero(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <HeroContainer ref={ref}>
      <HeroWrapper>
        <HeroMain>
          <HeroLeft>
            <HeroTitle>
              {homeData[0]?.meta_box?.hero_section?.hero_title}
            </HeroTitle>
            <HeroListWrapper>
              {heroData.map((item, i) => (
                <HeroList
                  key={i}
                  className={`hero-text ${setHero(item.name)}`}
                  onClick={() => handleClick(item.name)}
                  data-replace={item.name}
                >
                  {item.name}
                </HeroList>
              ))}
            </HeroListWrapper>
          </HeroLeft>
          <Swiper
            slidesPerView={1}
            initialSlide={activeIndex}
            allowTouchMove={false}
            onSwiper={(swiper) => {
              setActiveIndex(swiper);
            }}
            className="swiper"
            speed={2000}
          >
            {heroData.map((data, i) => (
              <SwiperSlide
                key={i}
                className={`swiper-slider ${
                  nextIndex === i ? "slide-fade" : ""
                }`}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <LottieStyle
                  key={currentHero}
                  animationData={data?.animation}
                  loop={true}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </HeroMain>
        <ScrollDownContainer>
          <ScrollDownWrapper>
            <MouseImg
              src={homeData[0]?.meta_box?.hero_section?.hero_scroll_down?.mouse}
              alt="mouse"
            />
            <MouseBarImg
              src={
                homeData[0]?.meta_box?.hero_section?.hero_scroll_down?.mouse_bar
              }
              alt="mouse-bar"
              id="animated-path"
            />
          </ScrollDownWrapper>
          <ScrollDownText>
            {homeData[0]?.meta_box?.hero_section?.hero_scroll_down?.scroll_text}
          </ScrollDownText>
        </ScrollDownContainer>
      </HeroWrapper>
    </HeroContainer>
  );
};

export default Hero;

const HeroContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;

  @media screen and (max-width: 480px) {
    height: 100dvh;
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;
  width: 75rem;
  height: 570px;

  @media screen and (min-width: 1025px) and (max-width: 1600px) {
    gap: 5rem;
    scale: 0.8;
  }

  @media screen and (min-width: 1600px) and (max-width: 1799px) {
    gap: 5rem;
  }

  @media screen and (min-width: 1800px) {
    gap: 5rem;
  }
`;

const HeroMain = styled.div`
  display: flex;
  gap: 14rem;

  @media screen and (max-width: 480px) {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    padding: 0 0;
    gap: 0rem;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    padding: 0 0;
    gap: 0rem;
  }
`;

const HeroTitle = styled.p`
  color: #8d93ab;
  line-height: 1.19;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  min-height: 100%;

  @media screen and (max-width: 480px) {
    min-height: max-content;
    gap: 1rem;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    min-height: max-content;
    gap: 1rem;
  }
`;

const HeroListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 1rem;

  @media screen and (max-width: 480px) {
    gap: 1rem;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    gap: 1rem;
  }
`;

const HeroList = styled.div`
  font-weight: 700;
  font-size: 90px;
  color: #f1f3f8;
  cursor: pointer;
  height: 100%;

  @media screen and (max-width: 480px) {
    font-size: 35px;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    font-size: 64px;
  }
`;

const LottieStyle = styled(Lottie)`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 480px) {
    width: 320px;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    width: 80%;
  }
`;

const ScrollDownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 100%;

  @media screen and (max-width: 480px) {
    display: none;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    display: none;
  }
`;

const ScrollDownWrapper = styled.div`
  position: relative;
  width: 17px;
  height: 24px;
`;

const MouseImg = styled.img`
  width: 17px;
  height: 24px;
  transform: scaleX(1.1);
`;

const MouseBarImg = styled.img`
  position: absolute;
  top: 30%;
  left: 41%;
  transform: translate(-50%, -50%);
`;

const ScrollDownText = styled.p`
  color: #8d93ab;
  font-weight: 400;
  margin-top: 4px;
`;
