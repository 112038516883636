import { motion } from "framer-motion";
import React, { useRef } from "react";
import styled from "styled-components";
import { Player } from "@lottiefiles/react-lottie-player";

const TalentsCard = ({ data, i }) => {
  const animationRef = useRef();

  function runAnim() {
    if (animationRef) {
      animationRef?.current?.play();
    }
  }

  return (
    <TalentBlock
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 150 },
      }}
      transition={{
        duration: 1.5,
        delay: i * 0.2,
        ease: [0.42, 0, 0.58, 1],
      }}
      onMouseEnter={runAnim}
    >
      <TalentImg>
        <div>
          <Player
            ref={animationRef}
            loop={false}
            autoPlay={false}
            src={data?.animation}
          ></Player>
        </div>
      </TalentImg>
      <TalentTitle>{data.title}</TalentTitle>
      <TalentDesc
        dangerouslySetInnerHTML={{
          __html: data.description,
        }}
      />
    </TalentBlock>
  );
};

export default TalentsCard;

const TalentBlock = styled(motion.div)`
  display: flex;
  flex-direction: column;
  max-width: 380px;
  gap: 1.5rem;
  width: max-content;

  @media screen and (max-width: 480px) {
    width: 100%;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    width: 100%;
  }
`;

const TalentImg = styled.div`
  width: 40px;
  height: 40px;

  @media screen and (max-width: 480px) {
    width: 40px;
    height: 40px;
  }
`;

const TalentTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #1f1f1f;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1441px) {
    font-size: 17px;
  }
`;

const TalentDesc = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: #393b44;
  line-height: 1.6;
  letter-spacing: 0;
  max-width: 270px;

  @media screen and (max-width: 480px) {
    margin-top: 0rem;
    font-size: 13px;
    line-height: 1.6;
    min-width: 100%;
  }

  @media screen and (min-width: 1441px) {
    font-size: 16px;
  }
`;
