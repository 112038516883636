import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import hamburger from "../assets/hamburger.svg";
import { AppContext } from "../context";
import logo from "../assets/logo.json";
import whiteLogo from "../assets/whiteLogo.json";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Lottie from "lottie-react-web";

const Navbar = () => {
  const { isSidebarOpen, setIsSidebarOpen, setIsHero, isHero } =
    useContext(AppContext);

  //These integers determine the direction of the animation
  const REVERSE = -1;
  const FORWARD = 1;

  const [direction, setDirection] = useState(-1);

  useEffect(() => {
    function setBlackIconDirection() {
      if (isHero) {
        setDirection(REVERSE);
      } else if (!isHero && !isSidebarOpen) {
        setDirection(FORWARD);
      }
    }
    setBlackIconDirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction, isHero, isSidebarOpen]);

  return (
    <NavContainer>
      <Container>
        <AnimationContainer className="fade-right-animation" onClick={() => window?.fullpage_api?.moveTo(1)}>
          {isSidebarOpen ? (
            <Lottie
              direction={!isSidebarOpen ? FORWARD : REVERSE}
              options={{
                animationData: whiteLogo,
                loop: false,
              }}
            />
          ) : (
            <Lottie
              direction={direction}
              options={{
                animationData: logo,
                loop: false,
              }}
            />
          )}
        </AnimationContainer>
        {
          <>
            {isSidebarOpen ? (
              <CloseButton
                onClick={() => {
                  setIsSidebarOpen(false);
                }}
              />
            ) : (
              <HamburgerIcon
                src={hamburger}
                alt="hamburger-svg"
                onClick={() => {
                  setIsSidebarOpen(true);
                  setIsHero(false);
                }}
              />
            )}
          </>
        }
      </Container>
    </NavContainer>
  );
};

export default Navbar;

const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  height: 3rem;
  z-index: 70;
  top: 0px;
  margin-top: 1.3rem;
  background-color: transparent;

  @media screen and (max-width: 480px) {
    height: 1.5rem;
  }
`;

const Container = styled.div`
  width: 94.5%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background-color: transparent;

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

const AnimationContainer = styled.div`
  width: 130px;
  cursor: pointer;
`;

const HamburgerIcon = styled.img`
  width: 28px;
  height: 20px;
  cursor: pointer;
`;

const CloseButton = styled(XMarkIcon)`
  width: 32px;
  font-weight: 200;
  cursor: pointer;
`;
