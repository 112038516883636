import React, { useContext } from "react";
import { styled } from "styled-components";
import { AppContext } from "../context";
import { motion } from "framer-motion";
import reinvest from "../assets/reinvest.json";
import { SocialGoodAnimationPlayer } from "../components";

const SocialGood = () => {
  const { homeData, isFooter } = useContext(AppContext);

  const visionData = homeData[0]?.meta_box?.vision_section;

  return (
    <Container id="Vision">
      <Background
        initial={{ width: "100%", height: "max-content" }}
        animate={{
          width: isFooter ? "100vw" : "100%",
          height: isFooter ? "100vh" : "max-content",
        }}
        transition={{ duration: 0.5 }}
      >
        <Wrapper>
          <Content
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 200 },
            }}
            transition={{ duration: 1.3, ease: "easeInOut" }}
          >
            <LeftContent>
              <Header>{visionData?.section_title}</Header>
              <DescTitle
                dangerouslySetInnerHTML={{
                  __html: visionData?.section_header,
                }}
              />
              <VisionDesc
                dangerouslySetInnerHTML={{
                  __html: visionData?.section_description,
                }}
              />
            </LeftContent>
            <ImageContainer>
              <VisionImg>
                <SocialGoodAnimationPlayer animation={reinvest} />
              </VisionImg>
            </ImageContainer>
          </Content>
        </Wrapper>
      </Background>
    </Container>
  );
};

export default SocialGood;

const Container = styled.section`
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 480px) {
    width: 90vw;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    width: 90vw;
    display: flex;
    justify-content: center;
  }
`;
const Background = styled(motion.div)`
  background: #393b44;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 40;
  width: 1000px;
  background: #393b44;
  padding: 0px 90px 0px 90px;
  color: white;
  height: 539.83px;
  position: sticky;
  overflow: clip;

  @media screen and (max-width: 480px) {
    padding: 20px;
    padding-bottom: 0px;
    width: 100%;
    flex-direction: column;
    gap: 0rem;
    overflow: hidden;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    display: flex;
    padding: 20px;
    padding-bottom: 0px;
    width: 100%;
    height: 85%;
    flex-direction: column;
    gap: 0rem;
    overflow: hidden;
    align-items: center;
  }
`;

const Content = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  width: 98%;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 0rem;
    overflow: hidden;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    flex-direction: column;
    gap: 0rem;
    overflow: hidden;
  }
`;

const Header = styled.h4`
  color: #d6e0f0;
  margin: 0;
  font-weight: 500;
  line-height: 1.5;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const DescTitle = styled.h2`
  font-size: 45px;
  padding: 0;
  margin: 2rem 0;
  font-weight: 700;
  max-width: 288px;
  letter-spacing: 0px;
  height: min-content;
  line-height: 1.2;

  @media screen and (max-width: 480px) {
    margin-right: 0rem;
    font-size: 31px;
    line-height: 1.2;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 482.5px;
  gap: 0rem;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 480px) {
    width: 100%;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    width: 100%;
  }
`;

const VisionDesc = styled.p`
  display: flex;
  font-weight: 300;
  line-height: 1.6;
  margin: 0;
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #f8fafc;

  @media screen and (max-width: 480px) {
    font-size: 13px;
    line-height: 1.6;
    width: 100%;
  }
`;

const VisionImg = styled.div`
  margin-top: 6rem;
  object-fit: contain;
  width: 400px;

  @media screen and (max-width: 480px) {
    margin-top: 0.5rem;
    width: 250px;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    margin-top: 6rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  min-width: 400px;
  @media screen and (max-width: 480px) {
    min-width: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    min-width: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
