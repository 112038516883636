import React from "react";
import GIcon from "../assets/GIcon";
import styled, { keyframes } from "styled-components";

const Loader = () => {
  const widthValue = "5rem";
  return (
    <Container>
      <LoaderWrapper>
        <GIcon width={widthValue} mr={widthValue} />
      </LoaderWrapper>
    </Container>
  );
};

export default Loader;

const pendulumAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  z-index: 80;
  height: 100vh;
  width: 100%;
  background-color: white;
`;

const LoaderWrapper = styled.div`
  animation: ${pendulumAnimation} 2s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
`;
