export const API_URL = 'https://aguadulcehq.com/index.php/wp-json/wp/v2/';
export const HOMEDATA_URL = `${API_URL}pages?slug=homepage`;
export const MENU_URL = `${API_URL}sidebar-menu`;

const maxMobileWidth = 820;
export const mobileView = window.innerWidth <= maxMobileWidth;

// VideoAsk script implemented through Google Tag Manager
export const GoogleTagManager = `
(function(w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-N3B7V4Q');
`;
