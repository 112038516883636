import React, { useContext } from "react";
import { styled } from "styled-components";
import { AppContext } from "../context";
import { AnimatePresence, motion } from "framer-motion";

const Footer = ({ setWheelDisabled }) => {
  const { isFooter, setIsFooter, homeData } = useContext(AppContext);

  const handleWheel = (e) => {
    const deltaY = e.deltaY;
    if (deltaY < 0) {
      setWheelDisabled(true);
      setIsFooter(false);
    }
  };

  const footerData = homeData[0]?.acf?.footer_section;

  return (
    <div>
      <AnimatePresence>
        {isFooter && (
          <Container
            initial={{ scale: 1, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{
              scaleX: 0.22,
              scaleY: 0.1,
              opacity: 1,
              zIndex: 0,
              transition: { duration: 0 },
            }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            onWheel={handleWheel}
          >
            <Wrapper>
              <GridWrap>
                <LeftContent>
                  <TitleText
                    dangerouslySetInnerHTML={{
                      __html: footerData.header,
                    }}
                  />
                  <SubHeader>{footerData.sub_header}</SubHeader>
                  <MobileDesc
                    dangerouslySetInnerHTML={{
                      __html: footerData.description,
                    }}
                  />
                </LeftContent>
                <RightContent>
                  <Desc
                    dangerouslySetInnerHTML={{
                      __html: footerData.description,
                    }}
                  />
                  <DescTitle
                    dangerouslySetInnerHTML={{
                      __html: footerData.greetings,
                    }}
                  />
                  <FlexCol>
                    <Email href="mailto:info@aguadulcehq.com">
                      {footerData.email}
                    </Email>
                    <Address
                      dangerouslySetInnerHTML={{
                        __html: footerData.address,
                      }}
                    />
                  </FlexCol>
                </RightContent>
              </GridWrap>
            </Wrapper>
          </Container>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Footer;

const Container = styled(motion.section)`
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background: #393b44;
  z-index: 80;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  height: 462px;

  @media screen and (max-width: 480px) {
    width: 90%;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    width: 90%;
  }
`;

const TitleText = styled.p`
  color: #d6e0f0;

  @media screen and (max-width: 480px) {
    font-size: 11px;
  }
`;

const GridWrap = styled.div`
  display: flex;
  width: 1190px;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

const SubHeader = styled.h2`
  font-size: 45px;
  margin-right: 5rem;
  color: #fcfdfd;

  @media screen and (max-width: 480px) {
    font-size: 29px;
    width: 100%;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    font-size: 40px;
    margin-right: 2rem;
  }
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescTitle = styled.h2`
  font-size: 45px;
  margin-right: 5rem;

  @media screen and (max-width: 480px) {
    font-size: 29px;
    width: 100%;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    font-size: 40px;
    margin-right: 2rem;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const Desc = styled.p`
  font-size: 16px;
  margin-bottom: 200px;
  line-height: 1.6;
  width: 95%;
  font-weight: 300;
  color: #f8fafc;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const MobileDesc = styled.p`
  display: none;
  font-size: 16px;
  margin-bottom: 50px;
  line-height: 1.6;
  width: 95%;
  font-weight: 300;
  color: #f8fafc;

  @media screen and (max-width: 480px) {
    width: 100%;
    display: block;
    font-size: 14px;
  }
`;

const Email = styled.a`
  font-size: 22px;
  font-weight: 700;
  line-height: 1.09;
  margin-top: 3rem;

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const Address = styled.p`
  color: rgba(248, 250, 252, 0.6);
  line-height: 1.5;
  font-size: 16px;
  margin-top: 1rem;
  width: 90%;
  font-weight: 300;

  @media screen and (max-width: 480px) {
    width: 100%;
    display: block;
    font-size: 14px;
  }
`;
