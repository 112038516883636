import * as React from "react";
import axios from "axios";
import { HOMEDATA_URL, MENU_URL } from "./helpers";

export const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [homeData, setHomeData] = React.useState([]);
  const [heroData, setHeroData] = React.useState([]);
  const [isHero, setIsHero] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [sidebarData, setSidebarData] = React.useState([]);
  const [isFooter, setIsFooter] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get(HOMEDATA_URL)
      .then((response) => {
        setHomeData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    if (
      homeData &&
      Array.isArray(homeData[0]?.meta_box?.hero_section?.hero_list)
    ) {
      const fetchHeroData = async () => {
        let counter = 1;
        const animations = await Promise.all(
          homeData[0].meta_box.hero_section.hero_list.map(async (hero) => {
            const response = await fetch(hero.animation);
            const animationData = await response.json();
            return {
              id: counter++,
              name: hero.hero_name,
              animation: animationData,
            };
          })
        );

        setHeroData(animations);
      };
      fetchHeroData();
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 500);

      // Clear the timeout when the component unmounts
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [homeData]);

  React.useEffect(() => {
    axios
      .get(MENU_URL)
      .then((response) => {
        setSidebarData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <AppContext.Provider
      value={{
        homeData,
        heroData,
        isHero,
        setIsHero,
        isLoading,
        setIsLoading,
        isSidebarOpen,
        setIsSidebarOpen,
        sidebarData,
        isFooter,
        setIsFooter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
