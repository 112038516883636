const GIcon = ({ gColor, width, mr }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 166 166"
      width={width}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: `${mr ? `${mr}` : "0rem"}`,
      }}
      className="flex justify-center items-center w-8"
      preserveAspectRatio="xMidYMid meet"
    >
      <g clipPath="url(#__lottie_element_60)">
        <g
          clipPath="url(#__lottie_element_62)"
          transform="matrix(1,0,0,1,0,13)"
          opacity="1"
        >
          <g
            transform="matrix(1,0,0,1,89.39999389648438,36.847999572753906)"
            opacity="1"
          >
            <g opacity="1" transform="matrix(1,0,0,1,0,0)">
              <path
                fill={gColor}
                fillOpacity="1"
                d=" M27.448999404907227,41.62200164794922 C19.631999969482422,41.62200164794922 13.276000022888184,35.257999420166016 13.276000022888184,27.448999404907227 C13.276000022888184,19.632999420166016 19.631999969482422,13.276000022888184 27.448999404907227,13.276000022888184 C35.26599884033203,13.276000022888184 41.62200164794922,19.632999420166016 41.62200164794922,27.448999404907227 C41.62200164794922,35.257999420166016 35.26599884033203,41.62200164794922 27.448999404907227,41.62200164794922z M56.79600143432617,0 C56.79600143432617,0 27.624000549316406,0 27.624000549316406,0 C27.624000549316406,0 27.427000045776367,0 27.427000045776367,0 C27.427000045776367,0 27.23699951171875,0 27.23699951171875,0 C27.23699951171875,0 26.975000381469727,0 26.975000381469727,0 C26.975000381469727,0 26.975000381469727,0.007000000216066837 26.975000381469727,0.007000000216066837 C12.057000160217285,0.24799999594688416 0,12.465999603271484 0,27.448999404907227 C0,42.577999114990234 12.312000274658203,54.89099884033203 27.448999404907227,54.89099884033203 C42.577999114990234,54.89099884033203 54.89099884033203,42.577999114990234 54.89099884033203,27.448999404907227 C54.89099884033203,21.44300079345703 52.94900131225586,15.887999534606934 49.665000915527344,11.36299991607666 C53.94900131225586,8.991000175476074 56.79600143432617,4.795000076293945 56.79600143432617,0z"
              ></path>
            </g>
          </g>
          <g
            transform="matrix(1,0,0,1,89.59700012207031,92.38299560546875)"
            opacity="1"
          >
            <g opacity="1" transform="matrix(1,0,0,1,0,0)">
              <path
                fill={gColor}
                fillOpacity="1"
                d=" M13.217000007629395,21.85099983215332 C5.144999980926514,26.099000930786133 0.050999999046325684,34.63100051879883 0,43.95800018310547 C0,43.95800018310547 0,44.183998107910156 0,44.183998107910156 C0,44.183998107910156 0.07999999821186066,44.13999938964844 0.07999999821186066,44.13999938964844 C0.07999999821186066,44.13999938964844 11.925000190734863,37.85599899291992 11.925000190734863,37.85599899291992 C11.925000190734863,37.85599899291992 36.900001525878906,24.60300064086914 36.900001525878906,24.60300064086914 C36.900001525878906,24.60300064086914 41.54199981689453,22.14299964904785 41.54199981689453,22.14299964904785 C44.00199890136719,20.836999893188477 46.176998138427734,19.13599967956543 48.01599884033203,17.128999710083008 C49.198001861572266,15.859000205993652 50.23500061035156,14.458000183105469 51.125,12.968999862670898 C53.402000427246094,9.13700008392334 54.67900085449219,4.664000034332275 54.694000244140625,0 C54.694000244140625,0 38.83399963378906,8.348999977111816 38.83399963378906,8.348999977111816 C38.83399963378906,8.348999977111816 13.217000007629395,21.85099983215332 13.217000007629395,21.85099983215332z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GIcon;
