import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { AppContext } from "../context";
import { motion } from "framer-motion";
import TalentsCard from "../components/TalentsCard";

const Talents = () => {
  const { homeData } = useContext(AppContext);

  const [talentInfo, setTalentInfo] = useState([]);

  const talentData = homeData[0]?.meta_box?.talents_section;

  useEffect(() => {
    async function fetchTalents() {
      const talentQuery = await Promise.all(
        talentData?.talent_card_data?.map(async (data) => {
          const res = await fetch(data?.talents_image);
          const animationData = await res.json();
          return {
            title: data?.talents_title,
            animation: animationData,
            description: data?.talents_description,
          };
        })
      );
      setTalentInfo(talentQuery);
    }
    if (talentData) {
      fetchTalents();
    }
  }, [talentData]);

  return (
    <Container id="Team">
      <Wrapper>
        <TopContent
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: 200 },
          }}
          transition={{ duration: 1.5 }}
        >
          <TitleText>{talentData?.section_title}</TitleText>
          <DescText>{talentData?.section_header}</DescText>
        </TopContent>
        <TalentContainer>
          {talentInfo?.map((data, i) => (
            <TalentsCard data={data} key={i} i={i} />
          ))}
        </TalentContainer>
      </Wrapper>
    </Container>
  );
};

export default Talents;

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 11rem;
  height: 100dvh;

  @media screen and (max-height: 739px) {
    transform: scale(0.8, 0.8);
  }

  @media screen and (max-width: 480px) {
    padding: 0 1.5rem;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    padding: 0 4rem;
  }
`;

const Wrapper = styled(motion.div)`
  gap: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    padding-top: 1rem;
  }
`;

const TopContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const TitleText = styled.h4`
  color: #8d93ab;
  font-weight: 400;
  padding-top: 10px;
  line-height: 1.19;
  font-size: 1rem;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    font-size: 14px;
  }
`;

const DescText = styled.h2`
  color: #1f1f1f;
  line-height: 1.2;
  max-width: 530px;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0;

  @media screen and (max-width: 480px) {
    font-size: 29px;
    margin-top: 0rem;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    font-size: 40px;
  }
`;

const TalentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
  margin-top: 8rem;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 0rem;
    gap: 2rem;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }
`;
