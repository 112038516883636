import React, { useRef } from "react";
import styled from "styled-components";
import { Player } from "@lottiefiles/react-lottie-player";

const ServicesCard = ({ data }) => {
  const animationRef = useRef();

  // Function plays the animation
  function runAnim() {
    if (animationRef) {
      animationRef?.current?.play();
    }
  }

  return (
    <ServicesContainer onMouseEnter={runAnim}>
      <ServicesIcon>
        <div>
          <Player
            ref={animationRef}
            loop={false}
            autoPlay={false}
            src={data?.animation}
          ></Player>
        </div>
      </ServicesIcon>
      <ServicesTitle>{data?.title}</ServicesTitle>
      <ServicesWrapper>
        {Object.values(data?.services).map((service, i) => (
          <span key={i}>{service}</span>
        ))}
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default ServicesCard;

const ServicesTitle = styled.h4`
  font-weight: 700;
  color: #1f1f1f;
  line-height: 1.19;
  font-size: 1rem;
`;

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: max-content;

  @media screen and (max-width: 480px) {
    border-top: 1px solid rgba(214, 224, 240, 0.4);
    height: 100%;
    padding: 1.5rem 0;
    justify-content: center;
  }
`;

const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  line-height: 2;
  color: #393b44;
  font-size: 1rem;
  letter-spacing: 0;
  margin-top: -0.5rem;
  font-weight: 300;
`;

const ServicesIcon = styled.div`
  width: 40px;
  height: 40px;
`;
