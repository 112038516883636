import Lottie from "react-lottie-player";
import React, { useEffect, useRef, useState } from "react";

const SocialGoodAnimationPlayer = ({ animation }) => {
  const animationRef = useRef();

  const [isPlaying, setIsPlaying] = useState(false);

  function runAnim() {
    setIsPlaying(true);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsPlaying(false);
    }, 3000);
    // Clear the timeout when the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isPlaying]);

  return (
    <div onMouseOver={runAnim}>
      <Lottie
        ref={animationRef}
        loop
        autoPlay={false}
        animationData={animation}
        play={isPlaying}
      />
    </div>
  );
};

export default SocialGoodAnimationPlayer;
