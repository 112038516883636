import React, { useContext, useEffect, useRef } from "react";
import { styled, keyframes } from "styled-components";
import GIcon from "../assets/GIcon";
import { AnimatePresence, motion } from "framer-motion";
import { AppContext } from "../context";

const Sidebar = () => {
  const { isSidebarOpen, sidebarData, setIsSidebarOpen, setIsFooter } =
    useContext(AppContext);

  const scrollRef = useRef(null);

  const menuList =
    sidebarData[0]?.meta_box?.sidebar_content?.menu_items?.menu_item;

  useEffect(() => {
    // On component mount, disable overflow
    document.body.style.overflow = "hidden";

    // On component unmount, enable overflow
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleLinkClick = (data, index) => {
    window?.fullpage_api?.moveTo(index + 2);
    setIsSidebarOpen(false);
    if (data === "Contact Us") {
      window?.fullpage_api?.moveTo(index + 1);
      setTimeout(() => {
        setIsFooter(true);
      }, 1500);
    }
  };

  return (
    <AnimatePresence>
      {isSidebarOpen && (
        <SidebarContainer
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <SidebarContent>
            <SidebarWrapper>
              <FlexCol>
                {menuList?.map((data, i) => (
                  <ModalLink
                    ref={scrollRef}
                    key={i}
                    data-replace={data}
                    className="modalLinks"
                    onClick={() => handleLinkClick(data, i)}
                  >
                    <span>{data}</span>
                  </ModalLink>
                ))}
              </FlexCol>
              <BottomSection href="mailto:info@aguadulcehq.com">
                <StyledParagraph>
                  {sidebarData[0]?.meta_box?.sidebar_content?.sidebar_cta}
                </StyledParagraph>
                <CodeContainer>
                  <CodeHeading>
                    {sidebarData[0]?.meta_box?.sidebar_content?.sidebar_cta2}
                  </CodeHeading>
                  <CodeIcon width="24px" />
                </CodeContainer>
              </BottomSection>
            </SidebarWrapper>
          </SidebarContent>
        </SidebarContainer>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;

const slideInAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const SidebarContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  font-weight: 700;
  height: 100vh;
  width: 100vw;
  background-color: rgba(57, 59, 68, 0.79);
  display: flex;
  flex-direction: row-reverse;
  z-index: 60;
`;

const SidebarContent = styled.div`
  width: 25%;
  background-color: #f2f6f9;
  padding: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  animation: ${slideInAnimation} 0.5s ease-in-out;
  z-index: 60;
  padding-top: 5rem;

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 50px;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    width: 100%;
  }
`;

const ModalLink = styled.a`
  font-size: 45px;
  font-weight: bold;
  width: max-content;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-size: 35px;
  }
`;

const CodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CodeHeading = styled.div`
  margin: 0;
  font-size: 22px;
`;

const CodeIcon = styled(GIcon)`
  width: 8rem;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
`;

const SidebarWrapper = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
`;

const StyledParagraph = styled.p`
  color: #8d93ab;
  line-height: 1.21;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 25px;
`;

const BottomSection = styled.a`
  border-top: 1px solid rgba(57, 59, 68, 0.2);
  padding-top: 30px;
`;
