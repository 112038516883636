/* eslint-disable no-lone-blocks */
import ReactFullpage from "@fullpage/react-fullpage";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Services, Footer, Hero, SocialGood, Talents } from "./sections";
import { Loader, Navbar, Sidebar } from "./components";
import { AppContext } from "./context";
import { useContext, useEffect, useState } from "react";
import { GoogleTagManager, mobileView } from "./helpers";

const pluginWrapper = () => {
  /*
   * require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.
   */
};

function App() {
  const { isLoading, setIsFooter, isSidebarOpen, isFooter } =
    useContext(AppContext);

  const [isWheelDisabled, setWheelDisabled] = useState(false);
  const [constraint, setConstraint] = useState(true);

  // Logic resets the scroll wheel when the footer is closed after a delay
  useEffect(() => {
    const delay = 1000;
    if (isWheelDisabled) {
      setTimeout(() => {
        setWheelDisabled(false);
      }, delay);
    }
  }, [isWheelDisabled]);

  // Scroll Logic to open the Footer from the Social good section
  const handleWheel = (e) => {
    const newDeltaY = e.deltaY;
    if (newDeltaY > 0 && !constraint) {
      setIsFooter(true);
    }
  };

  // Adds a constraint to control the Footer trigger
  function visionFunc(origin, destination, direction, trigger) {
    const upwardScroll = "up";
    const socialSectionIndex = 3;

    if (origin.index === socialSectionIndex && direction === upwardScroll) {
      setConstraint(true);
    } else {
      setConstraint(false);
    }
  }

  return (
    <div>
      <Helmet>
        <title>Aguadulce - Boutique Development Studio</title>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://aguadulcehq.com/wp-content/uploads/2023/08/favicon.ico"
        />
        <script>{GoogleTagManager}</script>
      </Helmet>
      <div>
        <Navbar />
        <Sidebar />
        {isLoading && <Loader />}
        <Footer setWheelDisabled={setWheelDisabled} />
        <DesktopContainer>
          <ReactFullpage
            pluginWrapper={pluginWrapper}
            scrollingSpeed={1500}
            scrollHorizontally={true}
            scrollHorizontallyKey={"YOUR KEY HERE"}
            touchSensitivity={3}
            css3={true}
            normalScrollElements={mobileView ? ".noscroll" : ''}
            onLeave={visionFunc}
            easingcss3={"cubic-bezier(0.535, 0.015, 0.240, 1.005)"}
            render={({ fullpageApi }) => {
              if (isWheelDisabled) {
                {
                  // Disbales page scrolling when scrolling away from the Footer
                  isWheelDisabled && fullpageApi?.setAllowScrolling(false);
                }
              } else if (isSidebarOpen) {
                // Disbales page scrolling when Sidebar is Open
                fullpageApi?.setAllowScrolling(false);
              } else {
                {
                  // Toggles page scrolling when Footer is open or closed
                  isFooter
                    ? fullpageApi?.setAllowScrolling(false)
                    : fullpageApi?.setAllowScrolling(true);
                }
              }
              return (
                <Container>
                  <ReactFullpage.Wrapper>
                    <div className="section">
                      <Hero />
                    </div>
                  </ReactFullpage.Wrapper>
                  <ReactFullpage.Wrapper>
                    <div className="section">
                      <Services />
                    </div>
                  </ReactFullpage.Wrapper>
                  <ReactFullpage.Wrapper>
                    <div className="section">
                      <Talents />
                    </div>
                  </ReactFullpage.Wrapper>
                  <ReactFullpage.Wrapper>
                    <div onWheel={handleWheel} className="section vision">
                      <SocialGood />
                    </div>
                  </ReactFullpage.Wrapper>
                </Container>
              );
            }}
          />
        </DesktopContainer>
      </div>
    </div>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12rem;
`;

const DesktopContainer = styled.main`
  display: block;
`;
