import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { useInView } from "react-intersection-observer";
import { AppContext } from "../context";
import { motion } from "framer-motion";
import { ServicesCard } from "../components";

const Services = () => {
  const { homeData, setIsHero } = useContext(AppContext);

  const [servicesData, setServicesData] = useState([]);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const ourServicesData = homeData[0]?.meta_box?.desc_section;

  useEffect(() => {
    async function fetchLottie() {
      const servicesData = await Promise.all(
        ourServicesData?.services_data?.map(async (data) => {
          const res = await fetch(data?.services_image);
          const animationData = await res.json();
          return {
            title: data?.services_title,
            animation: animationData,
            services: data?.services,
          };
        })
      );
      setServicesData(servicesData);
    }
    if (ourServicesData) {
      fetchLottie();
    }
  }, [ourServicesData]);

  useEffect(() => {
    if (inView) {
      setIsHero(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Container ref={ref}>
      <Wrapper>
        <ScrollWrapper className="noscroll">
          <TopContent
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 90 },
            }}
            transition={{ duration: 1, ease: "easeIn" }}
          >
            <DescTitle>{ourServicesData?.section_title}</DescTitle>
            <DescHeader
              dangerouslySetInnerHTML={{
                __html: ourServicesData?.section_header,
              }}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: ourServicesData?.section_description,
              }}
            />
          </TopContent>
          <BottomContent
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 90 },
            }}
            transition={{ duration: 1, delay: 0.2, ease: "easeIn" }}
          >
            <BottomWrapper>
              <ServicesHeader>{ourServicesData?.our_services}</ServicesHeader>
              <ServicesHeader
                style={{ visibility: "hidden" }}
                className="mobile-hide"
              >
                {ourServicesData?.our_services}
              </ServicesHeader>
            </BottomWrapper>
            <ServicesGrid>
              {servicesData?.map((data, i) => (
                <ServicesCard data={data} key={i} />
              ))}
            </ServicesGrid>
          </BottomContent>
        </ScrollWrapper>
      </Wrapper>
    </Container>
  );
};

export default Services;

const Container = styled.section`
  padding: 0 0;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: #f2f6f9;
  width: 940px;
  padding: 60px 100px 45px;
  overflow: hidden;

  @media screen and (max-width: 480px) {
    padding: 30px 30px;
    width: 70%;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    padding: 30px 30px;
    width: 70%;
  }

  @media screen and (min-width: 821px) and (max-width: 1649px) {
    scale: 0.79;
  }

  @media screen and (min-width: 1650px) {
    justify-content: center;
  }
`;

const ScrollWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  height: 100%;
  width: 100%;
  gap: 1rem;
  justify-content: center;

  @media screen and (max-width: 480px) {
    height: 83vh;
    justify-content: flex-start;
    overflow-y: scroll;
  }

  /* Tablets */
  @media screen and (min-width: 481px) and (max-width: 820px) {
    height: 83vh;
    justify-content: flex-start;
    overflow-y: scroll;
  }

  @media screen and (min-width: 1025px) and (max-width: 1600px) {
    justify-content: flex-start;
  }

  @media screen and (min-width: 1600px) and (max-width: 1799px) {
    justify-content: center;
  }

  @media screen and (min-width: 1800px) {
    justify-content: center;
  }
`;

const TopContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  width: 100%;

  @media screen and (min-width: 481px) and (max-width: 820px) {
    gap: 3rem;
  }
`;

const DescTitle = styled.h4`
  color: #8d93ab;
  font-weight: 400;
  line-height: 1.19;
  font-size: 16px;
  width: 100%;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    font-size: 20px;
  }
`;

const DescHeader = styled.h3`
  color: #1f1f1f;
  line-height: 1.4;
  letter-spacing: 0;
  font-size: 22px;
  margin: 0;
  font-weight: 700;

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    font-size: 20px;
  }
`;

const Description = styled.p`
  color: #393b44;
  font-weight: 300;
  line-height: 1.6;
  font-size: 16px;
  margin: 0;
  letter-spacing: 0;
  width: 100%;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    font-size: 16px;
  }
`;

const BottomContent = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  height: 100%;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    flex-direction: column;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 522px;
  width: 100%;
`;

const ServicesHeader = styled.h2`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  flex: 1;
  padding: 0 1.5rem;

  @media screen and (max-width: 480px) {
    font-size: 20px;
    margin: 5rem 0;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  width: 100%;
  row-gap: 5rem;
  column-gap: 10rem;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }

  @media screen and (min-width: 481px) and (max-width: 820px) {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 7rem;
  }
`;
